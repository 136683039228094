/* styles.css */

/* 全体のレイアウト */
body, html, #root {
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
}

/* アプリ全体のFlexboxレイアウト */
.app-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* ヘッダー（検索フォーム）のスタイル */
.search-form-container {
  background-color: #fff;
  padding: 20px;
  border-bottom: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 視覚的な区別 */
}

/* メインコンテンツのスタイル */
.main-container {
  padding: 20px;
  overflow-y: auto;
  flex: 1;
  background-color: #f9f9f9;
}

/* 入力コンテナ */
.input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  width: 100%;
}

/* 検索入力フィールド */
.search-input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  min-width: 200px;
}

/* ボタンコンテナ */
.button-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  width: 100%;
}

/* 検索ボタン */
.search-button {
  background-color: skyblue;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  transition: background-color 0.3s, transform 0.2s;
}

.search-button:hover {
  background-color: deepskyblue;
  transform: scale(1.05);
}

/* フィルターコンテナ */
.filter-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  flex-wrap: wrap;
  width: 100%;
}

/* フィルターセレクトボックス */
.filter-select {
  margin-left: 10px;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

/* エラーメッセージ */
.error-text {
  color: red;
  margin-top: 10px;
  text-align: center;
}

/* ローディングインジケーター */
.loading-indicator {
  text-align: center;
  margin: 20px 0;
}

/* 検索結果グリッド */
.results-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2列に固定 */
  gap: 20px;
  margin-top: 20px;
}

/* カードコンテナ */
.card-container {
  margin-bottom: 15px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}

.card-container:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 10px rgba(0,0,0,0.2);
}

/* カード画像 */
.card-image {
  height: 150px;
  object-fit: cover;
  border-radius: 8px 8px 0 0;
  width: 100%;
}

/* カードタイトル */
.card-title {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0;
}

/* カードテキスト */
.card-text p {
  font-size: 14px;
  margin: 5px 0;
}

/* ページネーションコンテナ */
.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center; /* 中央揃え */
  margin-top: 20px;
  margin-bottom: 20px;
  gap: 10px;
  flex-wrap: wrap; /* 必要に応じて折り返し */
  overflow-x: auto; /* 横スクロールを許可 */
  padding-bottom: 30px; /* 下部に余白を追加 */
}

/* ページネーションボタン */
.pagination-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  font-size: 16px;
  min-width: 100px; /* 最小幅を設定 */
  flex-shrink: 0; /* ボタンが縮まないように設定 */
  transition: background-color 0.3s, transform 0.2s;
}

.pagination-button:hover:not(:disabled) {
  background-color: #0056b3;
  transform: scale(1.05);
}

.pagination-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* 詳細ポップアップコンテンツ */
.popup-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* ポップアップ画像コンテナ */
.popup-image-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

/* ポップアップ画像 */
.popup-image {
  width: 200px; /* 画像の幅を調整 */
  height: auto;
  border-radius: 8px;
  object-fit: cover;
}

/* ポップアップ詳細 */
.popup-details {
  width: 100%;
}

.popup-details h3 {
  margin: 0 0 10px 0;
  font-size: 24px;
}

.popup-details p {
  margin: 5px 0;
  font-size: 14px;
}

/* 外部リンク */
.external-links {
  display: flex;
  gap: 10px;
  margin-top: 15px;
  flex-wrap: wrap;
}

/* リンクボタン */
.link-button {
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.link-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

/* カスタムポップアップのスタイル */
.custom-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  z-index: 1001; /* 検索フォームより上に表示 */
}

.custom-popup {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  max-width: 800px;
  width: 90%;
  display: flex;
  flex-direction: column;
  position: relative;
  max-height: 90vh; /* 高さを制限してスクロール可能に */
  overflow-y: auto;
}

/* ポップアップの閉じるボタン */
.custom-popup-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  background: transparent;
  border: none;
  cursor: pointer;
}

/* レスポンシブデザイン */
@media (max-width: 1200px) {
  /* 大画面でのグリッド列数を減らす */
  .results-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 992px) {
  /* タブレット向けのグリッド列数 */
  .results-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  /* 検索フォームのレイアウト調整 */
  .input-container {
    flex-direction: column;
    align-items: stretch;
  }

  .search-input {
    width: 100%;
    margin-bottom: 10px;
  }

  .button-container {
    margin-top: 0;
    margin-bottom: 10px;
    justify-content: flex-start;
  }

  .filter-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .filter-container label {
    margin-bottom: 5px;
  }

  .filter-select {
    width: 100%;
    margin-left: 0;
  }

  /* ポップアップのレイアウト調整 */
  .popup-content {
    flex-direction: column;
    align-items: center;
  }

  .popup-image-container {
    flex: 0 0 auto;
    width: 100%;
    max-width: 200px;
  }

  .popup-details {
    width: 100%;
  }

  .popup-details h3 {
    font-size: 20px;
  }

  /* グリッドを1列に変更 */
  .results-grid {
    grid-template-columns: 1fr;
  }

  /* ページネーションコンテナの調整 */
  .pagination-container {
    flex-direction: row; /* 縦並びから横並びに変更 */
    align-items: center; /* 中央揃え */
    gap: 10px; /* ボタン間のスペースを適切に設定 */
  }

  .pagination-button {
    width: auto; /* 幅を自動に */
    max-width: none; /* 最大幅の制限を解除 */
    padding: 10px 20px; /* パディングを維持 */
    font-size: 16px; /* フォントサイズを維持 */
  }
}

@media (max-width: 576px) {
  /* スマートフォン向けの追加調整 */

  /* 検索入力フィールドとボタンの調整 */
  .search-input {
    font-size: 14px;
    padding: 8px;
  }

  .search-button {
    font-size: 14px;
    padding: 8px 16px;
  }

  /* フィルターセレクトボックスの調整 */
  .filter-select {
    font-size: 14px;
    padding: 5px;
  }

  /* カードタイトルとテキストの調整 */
  .card-title {
    font-size: 16px;
  }

  .card-text p {
    font-size: 12px;
  }

  /* ポップアップ詳細の調整 */
  .popup-details h3 {
    font-size: 18px;
  }

  .popup-details p {
    font-size: 12px;
  }

  /* リンクボタンの調整 */
  .link-button {
    padding: 8px 12px;
    font-size: 14px;
  }

  /* ポップアップの幅をさらに調整 */
  .custom-popup {
    width: 95%;
    padding: 15px;
  }

  /* ページネーションボタンの調整 */
  .pagination-button {
    font-size: 16px;
    padding: 10px 0;
  }
}
