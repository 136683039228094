/* BottomTabNavigator.css */

:root {
    --header-height: 60px;
    --tabbar-height: 30px; /* タブバーの高さを30pxに設定 */
  }
  
  .container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
  }
  
  .header {
    background-color: #2196F3;
    padding: 12px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: var(--header-height);
    z-index: 1000;
    box-sizing: border-box;
  }
  
  .headerCenter {
    text-align: center;
  }
  
  .headerRight {
    position: absolute;
    right: 15px;
    display: flex;
    align-items: center;
  }
  
  .headerText {
    text-align: center;
    font-size: 1.2rem;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
  }
  
  .languageSwitchContainer {
    display: flex;
    gap: 5px;
    flex-shrink: 0;
  }
  
  .languageButton {
    background-color: white;
    color: #2196F3;
    border: none;
    padding: 3px 6px; /* パディングを4px 8pxから3px 6pxに縮小 */
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.7rem; /* フォントサイズを0.8remから0.7remに縮小 */
  }
  
  .tabBar {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 0; /* パディングを0に設定 */
    background-color: #EEE;
    width: 100%;
    height: var(--tabbar-height);
    z-index: 999; /* ヘッダーより低く設定 */
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    position: fixed;
    top: var(--header-height); /* ヘッダーの下に固定 */
  }
  
  .screenContainer {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    overflow-y: auto;
    margin-top: calc(var(--header-height) + var(--tabbar-height)); /* ヘッダー + タブバーの高さ分のマージン */
  }
  
  .tabActive,
  .tab {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0; /* パディングを0に設定 */
    cursor: pointer;
    font-size: 0.9srem; /* フォントサイズを0.7remから0.6remに縮小 */
    height: 100%; /* タブバー全体の高さに合わせる */
  }
  
  .tabActive {
    background-color: #ddd;
    color: tomato;
  }
  
  .tabText {
    color: gray;
    font-size: 0.9rem; /* フォントサイズを0.7remから0.6remに縮小 */
    margin-top: 1px; /* アイコンとの間隔を1pxに調整 */
  }
  
  .tabTextActive {
    color: tomato;
    font-size: 0.6rem; /* フォントサイズを0.7remから0.6remに縮小 */
  }
  
  .tab svg {
    width: 14px; /* アイコンの幅を16pxから14pxに縮小 */
    height: 14px; /* アイコンの高さを16pxから14pxに縮小 */
    margin-bottom: 1px; /* アイコンとテキストの間隔を1pxに調整 */
  }
  
  .popupOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2000;
    padding: 10px;
  }
  
  .popupContent {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 90%;
    width: 300px;
  }
  
  .buttonGroup {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    gap: 10px;
    flex-wrap: wrap;
  }
  
  .buttonClose,
  .buttonCancel {
    background-color: #2196F3;
    color: white;
    padding: 4px 8px; /* パディングを6px 12pxから4px 8pxに縮小 */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    flex: 1 1 40%;
    margin: 5px;
    font-size: 0.6rem; /* フォントサイズを0.7remから0.6remに縮小 */
  }
  
  @media (max-width: 600px) {
    .header {
      padding: 12px 10px;
    }
  
    .headerText {
      font-size: 1rem;
    }
  
    .languageButton {
      font-size: 0.6rem; /* フォントサイズを0.7remから0.6remに縮小 */
      padding: 3px 5px; /* パディングを4px 6pxから3px 5pxに縮小 */
    }
  
    .tabBar {
      top: var(--header-height);
      padding: 0; /* パディングを1px 0から0に設定 */
    }
  
    .screenContainer {
      margin-top: calc(var(--header-height) + var(--tabbar-height));
    }
  
    .tabBar svg {
      width: 14px; /* アイコンのサイズを14pxに設定 */
      height: 14px;
    }
  
    .tabActive,
    .tab {
      padding: 0; /* パディングを1px 0から0に設定 */
      font-size: 0.6rem; /* フォントサイズを0.6remに設定 */
    }
  
    .tabText,
    .tabTextActive {
      font-size: 0.6rem; /* フォントサイズを0.6remに設定 */
    }
  
    .buttonClose,
    .buttonCancel {
      padding: 3px 6px; /* パディングを5px 10pxから3px 6pxに縮小 */
      font-size: 0.6rem; /* フォントサイズを0.6remに設定 */
    }
  }
  